<template>

	<div v-if="auth_user" class="">

		<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">
			
			<div class="d-flex align-items-center">
				<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
					{{ label }}
				</div>
				<router-link 
					class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2"
					:class="$route.name == 'team' ? 'border-primary' : 'border-light'"
					:to="{ name: 'team' }"  
				>
					<span class="material-icons-outlined me-2">business</span>	
					Details	
				</router-link>

				<router-link 
					class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2"
					:class="$route.name == 'team.brand' ? 'border-primary' : 'border-light'"
					:to="{ name: 'team.brand' }"  
				>
					<span class="material-icons-outlined me-2">diamond</span>	
					Brand	
				</router-link>

				<router-link 
					class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2"
					:class="$route.name == 'team.members' ? 'border-primary' : 'border-light'"
					:to="{ name: 'team.members' }" 
				>
					<span class="material-icons-outlined me-2">groups</span>	
					Members	
				</router-link>

				<router-link 
					class="btn p-3 px-4 rounded-0 d-flex align-items-center justify-content-center border-0 border-bottom border-2"
					:class="$route.name == 'team.invite-member' ? 'border-primary' : 'border-light'"
					:to="{ name: 'team.invite-member' }"  
				>
					<span class="material-icons-outlined me-2">add_circle</span>	
					Invite team	
				</router-link>
			</div>
		</div>

	</div>
	
	<nav v-if="auth_user" class="w-100 d-flex flex-column justify-content-center">
	



	</nav>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'components.team_nav',

	props: {
		label: String
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	components: {
		
	}
}
</script>

<style>

</style>
