<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<team_nav label="Invite team members" />
			
						<div class="container h-100 py-4">

							<div class="row h-100 justify-content-center">

								<div class="col-12 col-md-10 col-lg-6">

									<div class="card mb-3">

										<div class="card-body">

											<p v-if="success" class="alert alert-success d-flex align-items-center">
												<span class="material-icons-outlined me-2">check_circle</span>
												Your details were updated successfully
											</p>

											<p v-if="error.message" class="alert alert-danger">
												{{ error.message }}
											</p>

											<form @submit.prevent="submit">

												<form_control
													label="Name"
													type="text"
													placeholder="eg: John Smith"
													name="name"
													:required="true"
													:error="error"
													v-model="name"
												/>

												<form_control
													label="Email address"
													type="email"
													placeholder="eg: john@areoi.io"
													name="email"
													:required="true"
													:error="error"
													v-model="email"
												/>

												<form_control
													label="Which best describes their role?"
													type="select"
													placeholder="Select role"
													name="job_role"
													:required="true"
													:error="error"
													:options="roles"
													v-model="job_role"
												/>

												<form_control
													label="Permissions"
													type="select"
													name="type"
													:options="store_permissions"
													:required="true"
													:error="error"
													v-model="type"
												/>

												<btn_submit 
													label="Invite team member" 
													icon="check"  
													:icon_after="true" 
													:loading="loading" 
												/>

											</form>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import team_nav from '@/components/team_nav'
import support_link from '@/components/support_link'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import user_service from '@/services/user_service'

export default {
	name: 'team.invite-member',

	components: {
		layout,
		main_nav,
		team_nav,
		support_link,
		form_control,
		btn_submit
	},

	data() {
		return {
			loading: false,
			name: null,
			email: null,
			job_role: null,
			type: 'admin',
			error: {},
			success: null,
			roles: [
				{
					name: 'Select role',
					value: null
				},
				{
					name: 'Agency Owner',
					value: 'owner'
				},
				{
					name: 'Account Manager',
					value: 'account'
				},
				{
					name: 'Designer',
					value: 'designer'
				},
				{
					name: 'Developer',
					value: 'developer'
				},
				{
					name: 'Other',
					value: 'other'
				},
			]
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'permissions', ['store_permissions'] ),

	},

	mounted()
	{
		
	},

	methods: {
		reset_error()
		{
			this.error = {}

			this.success = null
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			user_service.invite( {
				name: this.name,
				email: this.email,
				job_role: this.job_role,
				type: this.type
			}).then(() => {
				this.$router.push( { name: 'team.members' } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>

<style>

</style>
